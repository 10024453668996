<template>
    <section class="research-center">
        <Nav
            class="mt-5 mb-4"
            route-name="StrategicProjects"
            parent-page-name="Стратегические проекты"
            current-page-name="ИЦ «Сильный искусственный интеллект в промышленности»"
        />

        <div class="research-center__main-img d-none d-xl-flex">
            <div class="research-center__blurred-img mt-lg-auto ms-lg-auto d-flex align-items-end">
                <h2 class="research-center__title mt-auto ms-lg-5 mb-lg-5">
                    {{ getResearchCenterPageInfo('title') }}
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="research-center__logo ms-auto mb-5 me-5 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="research-center__title research-center__title--dark">
                {{ getResearchCenterPageInfo('title') }}
            </h2>
        </div>

        <div class="d-flex flex-column flex-lg-row my-5 research-center__statistics">
            <div class="col-12 col-lg-6">
                <div class="research-info-card d-flex flex-column flex-sm-row align-items-center">
                    <div class="research-info-card__img d-flex justify-content-center align-items-center p-4">
                        <img src="../../assets/views/research_center/gazprom.png" alt="" width="170" height="101">
                    </div>

                    <div class="research-info-card__text ms-4 mt-4 mt-sm-0">
                        {{ getResearchCenterCardInfo(2, 'text') }}
                    </div>
                </div>

                <hr style="color: #F2F2F2" class="mt-4">

                <div class="research-info-card d-flex flex-column flex-sm-row align-items-center mt-4">
                    <div class="research-info-card__img d-flex justify-content-center align-items-center p-4">
                        <img src="../../assets/views/research_center/rubles.png" alt="" width="170" height="104">
                    </div>

                    <div class="research-info-card__text ms-4 mt-4 mt-sm-0">
                        {{ getResearchCenterCardInfo(1, 'text') }}
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6 ms-lg-4 mt-4 mt-lg-0">
                <div class="research-card">
                    <div class="research-card__img-wrapper d-flex justify-content-center me-lg-4">
                        <img
                            class="research-card__img"
                            src="../../assets/views/research_center/ii.svg"
                            alt=""
                        >
                    </div>

                    <div class="research-card__text mt-4 me-lg-4">
                        {{ getResearchCenterCardInfo(3, 'text') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column flex-lg-row align-items-center research-center__task mt-5 py-5">
            <div class="col-12 col-lg-8 col-xl-6 d-flex justify-content-center justify-content-lg-start">
                <div class="task-img-card">
                    <img
                        class="task__img"
                        src="../../assets/views/research_center/research-center.png"
                        alt=""
                    >
                </div>
            </div>

            <div
                class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <h3 class="task__title">{{ getResearchCenterPageInfo('subtitle_1') }}</h3>
                <div class="task__text mt-3">
                    {{ getResearchCenterPageInfo('subtext_1') }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "ResearchCenter",

    components: {
        Nav
    },

    setup() {
        const store = useStore()
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getResearchCenterPage')
        const reserchCenterPage = computed(() => store.getters['pages/reserchCenterPage'])

        const getResearchCenterPageInfo = (key) => {
            if (reserchCenterPage.value) {
                return reserchCenterPage.value[key]
            }

            return ''
        }

        const getResearchCenterCardInfo = (id, key) => {
            if (reserchCenterPage.value) {
                return reserchCenterPage.value.horizontal_cards.find(card => card.id === id)[key]
            }

            return ''
        }

        return {
            getResearchCenterPageInfo,
            getResearchCenterCardInfo
        }
    }
}
</script>
